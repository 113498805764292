// core overrides
$theme-colors: (
        primary:                        #536c79,
        secondary:                      $gray-300,
        success:                        $green,
        info:                           $cyan,
        warning:                        $yellow,
        danger:                         $red,
        light:                          $gray-100,
        dark:                           $gray-800
);