// Here you can add other styles
/*
Bootstrap fixes
 */
.form-control.form-control-sm[type="date"] {
  padding: 3px 0.5rem
}

/*
ss-multiselect-dropdown fixes with bootstrap
 */
.form-control-sm .dropdown-toggle {
  padding-top: 6px;
  padding-bottom: 6px;
}

.form-control:focus {
  outline: 2px solid #8ad4ee;
}

/*
Other Styles
TODO: Move these styles in the components they belong to if they are component related
 */

a, .btn-link {
  color: #711c21;
}

.sidebar {
  background: #0f0f0f;
}

.sidebar .nav .nav-item .nav-link.active {
  background: #202020;
  color: #fff;
}

.sidebar .nav .nav-item .nav-link:hover {
  background: #711c21 !important;
}

.divider {
  margin: 15px 0px;
}

.card-header .btn {
  margin-top: 0px;
}

.card-header {
  background: #3e3e40;
  color: #fff;
}

.card {
  border: 1px solid #636363;
}

.page-link, pageination li a {
  color: #ed212d;
}

.page-item.active .page-link, .pagination li-active .page-link, .pagination li.active a {
  background: #ed212d;
  color: #000;
  border: 1px solid #ed212d;
}

.table > tbody > tr > td.active {
  background: #e6e6e6;
  width: 40%;
}

.card-body-request-padding {
  padding: 0px;
}

.table-request-padding-bottom {
  margin-bottom: 0px;
}

.card-header-border-bottom {
  border-bottom: 0px;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.card-header-result-details-top-table {
  background: #e6e6e6;
  color: #000;
}

.card-blockquote-notes {
  background: #ffffea;
}

.alert-new-order-padding-bottom {
  margin-bottom: 0;
}

.card {
  border: 1px solid #c2cfd6;
}

.text-info {
  color: #721b21 !important;
}

.btn-primary {
  color: #fff !important;
  background-color: #6c6c6c;
  border-color: #595959;
}

.btn-primary:hover {
  background-color: #711c21 !important;
  border-color: #6b191e !important;
}

.import-submit-button-margin-bottom {
  margin-bottom: 10px;
}

.new-request-background {
  background: #63c2de;
}

.pending-request-background {
  background: #F86C69;
}

.completed-request-background {
  background: #4dbd74;
}

.card-footer-user-info {
  padding: 70px 0;
  background: url("../assets/img/FB-Cover-01.jpg");
  background-size: cover;
}

.card-footer-login {
  padding: 0.75rem 1.25rem;
  background-color: #F76C69;
  border: 1px solid #c2cfd6;
  color: #fff;
}

.login-as-researcher-padding-bottom {
  margin-bottom: 10px;
}

//.webtrax-background-at-login{
//  background: url("../assets/img/background.jpg");
//  background-size: cover;
//}

.login-logo-background {
  background: #0f0f0f !important;
}

.spacer {
  margin-top: 5px;
}

.nested-modal-backdrop {
  box-shadow: 0 0 0 2500px rgba(1, 1, 1, .5);
}
